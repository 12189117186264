* {
    --containerWidth: 1200px;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.container,
.container-sm {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.container {
    max-width: var(--containerWidth);
}

.container-sm {
    max-width: calc(var(--containerWidth) / 1.5);
}
